import React from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/profile_pic.png';
import HeaderSocials from './HeaderSocials';
import FULLLOGO from '../../assets/logo-no-background_full.png';


const Header = () => {
  return (
    <header id='top'>
      <div className='container header__container'>
        <h5>Hello I'm</h5>
        <div className='header__container_logo'>
          <img src={FULLLOGO} alt='Felman Peralta' />
        </div>
        <h5 className='text-light'>Data Analyst | Business Intelligence | Developer | Engineer</h5>

        <CTA />
        <HeaderSocials />

        <div className='me'>
          <img src={ME} alt='Felman Peralta' />
        </div>

        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header;